import React, { ReactElement } from "react";

export default function CopierCostsLogo(): ReactElement {
    return (
        <svg
            width="238"
            height="35"
            viewBox="0 0 238 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_14_708_a0)">
                <path
                    d="M43.4115 28.007C41.9032 27.1485 40.6504 25.9041 39.7819 24.4016C38.9133 22.899 38.4602 21.1924 38.4691 19.4569C38.4541 17.7149 38.9131 16.0017 39.7968 14.5006C40.685 13.0056 41.9496 11.7696 43.4645 10.9159C44.9793 10.0621 46.6915 9.62031 48.4304 9.63461C49.8068 9.63407 51.169 9.91312 52.4343 10.4549C53.7179 11.0056 54.8503 11.8567 55.7362 12.9365C56.6863 14.1084 57.3076 15.5119 57.5366 17.003H54.0123C53.7289 15.7323 52.9863 14.6109 51.9269 13.8541C50.8653 13.13 49.6039 12.7557 48.3192 12.7836C47.1842 12.7741 46.0668 13.0642 45.0798 13.6247C44.1069 14.1864 43.3064 15.0037 42.765 15.9881C42.2066 17.0715 41.9152 18.2727 41.9152 19.4916C41.9152 20.7105 42.2066 21.9117 42.765 22.9951C43.3005 23.9761 44.1 24.7876 45.0729 25.3377C46.0675 25.8893 47.1888 26.172 48.3261 26.1579C49.6122 26.1941 50.8778 25.8297 51.9478 25.1152C52.9944 24.357 53.7227 23.2375 53.9915 21.9732H57.5297C57.3161 23.411 56.7357 24.7694 55.8444 25.9175C54.9532 27.0657 53.7811 27.9649 52.4413 28.5284C51.1709 29.0686 49.804 29.3453 48.4234 29.3417C46.6639 29.3501 44.9338 28.8894 43.4115 28.007V28.007Z"
                    fill="#084F92"
                />
                <path
                    d="M63.0769 28.3407C61.9544 27.6832 61.028 26.7376 60.3937 25.6019C59.7554 24.4349 59.4209 23.1261 59.4209 21.796C59.4209 20.4659 59.7554 19.1571 60.3937 17.9902C61.0259 16.851 61.9526 15.9027 63.0769 15.2444C64.2429 14.5587 65.5754 14.2075 66.9279 14.2295C68.287 14.2076 69.6262 14.5587 70.7998 15.2444C71.9277 15.9004 72.8572 16.8491 73.49 17.9902C74.1331 19.1618 74.4703 20.4768 74.4703 21.8134C74.4703 23.15 74.1331 24.465 73.49 25.6366C72.8543 26.776 71.9224 27.7224 70.7929 28.3755C69.6125 29.0503 68.2736 29.3983 66.914 29.3834C65.5638 29.3926 64.2368 29.032 63.0769 28.3407ZM69.0828 25.6644C69.7167 25.2807 70.2354 24.7332 70.5843 24.0795C70.9451 23.3712 71.1331 22.5875 71.1331 21.7925C71.1331 20.9976 70.9451 20.214 70.5843 19.5056C70.235 18.8481 69.7137 18.2978 69.0759 17.9137C68.4282 17.5357 67.6917 17.3365 66.9418 17.3365C66.1919 17.3365 65.4554 17.5357 64.8078 17.9137C64.17 18.2978 63.6486 18.8481 63.2993 19.5056C62.9386 20.214 62.7505 20.9976 62.7505 21.7925C62.7505 22.5875 62.9386 23.3712 63.2993 24.0795C63.6463 24.7346 64.1654 25.2826 64.8008 25.6644C65.454 26.0336 66.1915 26.2276 66.9418 26.2276C67.6921 26.2276 68.4296 26.0336 69.0828 25.6644V25.6644Z"
                    fill="#084F92"
                />
                <path
                    d="M80.58 14.6604V17.0586C81.0824 16.1943 81.8011 15.4757 82.6654 14.9732C83.5226 14.4695 84.5003 14.2076 85.4946 14.2155C86.7591 14.1974 88.0013 14.5502 89.0675 15.2304C90.1252 15.9045 90.9823 16.8504 91.5492 17.9692C92.1411 19.1493 92.4494 20.4514 92.4494 21.7716C92.4494 23.0919 92.1411 24.3939 91.5492 25.574C90.9787 26.6881 90.1252 27.6326 89.0745 28.3128C88.0217 28.9885 86.7941 29.3413 85.5432 29.3277C84.5486 29.3356 83.5698 29.0789 82.7071 28.5839C81.8711 28.1105 81.1774 27.4215 80.6981 26.5889L80.7676 28.2433V33.7627H77.4102V14.6604H80.58ZM87.003 25.713C87.6454 25.3403 88.1741 24.7996 88.5323 24.149C88.9048 23.4255 89.0992 22.6236 89.0992 21.8098C89.0992 20.9961 88.9048 20.1942 88.5323 19.4707C88.1766 18.8165 87.6474 18.2729 87.003 17.8997C86.3705 17.5294 85.6505 17.335 84.9176 17.3367C84.181 17.3356 83.4587 17.5402 82.8322 17.9275C82.1909 18.3208 81.6655 18.8773 81.3099 19.5402C80.9398 20.2408 80.7463 21.021 80.7463 21.8133C80.7463 22.6056 80.9398 23.3859 81.3099 24.0864C81.6686 24.7457 82.1965 25.2975 82.8392 25.6852C83.47 26.0602 84.1907 26.2572 84.9246 26.2552C85.6528 26.2591 86.3694 26.0721 87.003 25.713V25.713Z"
                    fill="#084F92"
                />
                <path
                    d="M95.5047 11.8937C95.3193 11.7139 95.1734 11.4974 95.0765 11.258C94.9795 11.0186 94.9336 10.7616 94.9416 10.5035C94.9323 10.2451 94.9776 9.98776 95.0746 9.74817C95.1717 9.50859 95.3182 9.29221 95.5047 9.1132C95.7088 8.92694 95.9478 8.78309 96.208 8.69001C96.4681 8.59693 96.7442 8.55648 97.0201 8.571C97.5857 8.54189 98.1401 8.73668 98.5633 9.1132C98.7474 9.29491 98.8936 9.51138 98.9933 9.75004C99.0931 9.9887 99.1445 10.2448 99.1445 10.5035C99.1445 10.7621 99.0931 11.0182 98.9933 11.2569C98.8936 11.4956 98.7474 11.712 98.5633 11.8937C98.1333 12.2673 97.575 12.4592 97.0062 12.429C96.4545 12.4564 95.9146 12.2639 95.5047 11.8937V11.8937ZM95.3587 14.6743H98.7162V28.9384H95.3587V14.6743Z"
                    fill="#084F92"
                />
                <path
                    d="M116.143 22.6752H105.021C105.1 23.3774 105.337 24.0525 105.716 24.6494C106.065 25.1831 106.543 25.6206 107.106 25.9215C107.672 26.2091 108.299 26.3568 108.934 26.3524C109.647 26.3835 110.355 26.215 110.978 25.8659C111.514 25.5313 111.926 25.031 112.153 24.4408H115.816C115.557 25.3397 115.106 26.172 114.495 26.8808C113.848 27.6455 113.041 28.2599 112.132 28.6811C110.444 29.4797 108.515 29.6051 106.738 29.0317C104.961 28.4583 103.47 27.2292 102.567 25.5947C101.937 24.4263 101.607 23.1197 101.607 21.7924C101.607 20.465 101.937 19.1584 102.567 17.99C103.197 16.8375 104.129 15.8785 105.263 15.216C106.397 14.5534 107.69 14.2123 109.004 14.2293C110.317 14.2068 111.611 14.5536 112.737 15.2303C113.811 15.8729 114.69 16.7956 115.281 17.8996C115.888 19.0172 116.201 20.2705 116.191 21.5421C116.212 21.8549 116.184 22.2373 116.143 22.6752ZM112.785 20.0823C112.736 19.547 112.528 19.0387 112.187 18.6226C111.817 18.1594 111.34 17.7922 110.797 17.552C110.232 17.2863 109.614 17.1509 108.99 17.1558C108.13 17.145 107.288 17.4052 106.585 17.8996C105.852 18.43 105.332 19.2037 105.118 20.0823H112.785Z"
                    fill="#084F92"
                />
                <path
                    d="M122.163 14.6605V17.2394C122.468 16.4781 122.987 15.8214 123.657 15.3487C124.343 14.8765 125.161 14.6332 125.993 14.6535H127.522V17.5383H126.174C125.458 17.5244 124.753 17.7175 124.144 18.0944C123.542 18.4889 123.071 19.0524 122.788 19.7141C122.444 20.5266 122.278 21.4038 122.302 22.2861V28.9177H118.944V14.6605H122.163Z"
                    fill="#084F92"
                />
                <path
                    d="M133.821 28.007C132.312 27.1485 131.06 25.9041 130.191 24.4016C129.323 22.899 128.869 21.1924 128.878 19.4569C128.863 17.7149 129.322 16.0017 130.206 14.5006C131.094 13.0056 132.359 11.7696 133.874 10.9159C135.389 10.0621 137.101 9.62031 138.84 9.63461C140.216 9.63407 141.578 9.91312 142.844 10.4549C144.127 11.0056 145.259 11.8567 146.145 12.9365C147.083 14.0961 147.699 15.4817 147.932 16.9544H144.408C144.124 15.6836 143.382 14.5622 142.322 13.8054C141.255 13.0835 139.988 12.714 138.701 12.7488C137.566 12.7393 136.448 13.0295 135.461 13.5899C134.488 14.1516 133.688 14.969 133.146 15.9534C132.588 17.0368 132.297 18.238 132.297 19.4569C132.297 20.6757 132.588 21.8769 133.146 22.9603C133.682 23.9414 134.481 24.7529 135.454 25.3029C136.449 25.8545 137.57 26.1373 138.707 26.1232C139.994 26.1593 141.259 25.795 142.329 25.0805C143.376 24.3223 144.104 23.2027 144.373 21.9385H147.911C147.697 23.3762 147.117 24.7346 146.226 25.8828C145.335 27.031 144.162 27.9301 142.823 28.4936C141.552 29.0339 140.185 29.3106 138.805 29.3069C137.058 29.321 135.338 28.8725 133.821 28.007Z"
                    fill="#084F92"
                />
                <path
                    d="M153.471 28.3407C152.349 27.6832 151.423 26.7377 150.788 25.6019C150.15 24.4349 149.815 23.1262 149.815 21.796C149.815 20.4659 150.15 19.1572 150.788 17.9902C151.42 16.8511 152.347 15.9028 153.471 15.2444C154.65 14.5793 155.98 14.2297 157.333 14.2297C158.686 14.2297 160.016 14.5793 161.194 15.2444C162.322 15.9004 163.252 16.8491 163.885 17.9902C164.528 19.1619 164.865 20.4768 164.865 21.8134C164.865 23.15 164.528 24.465 163.885 25.6367C163.251 26.7775 162.318 27.7243 161.187 28.3755C160.007 29.0503 158.668 29.3983 157.309 29.3834C155.958 29.3916 154.632 29.0311 153.471 28.3407ZM159.477 25.6645C160.113 25.2826 160.632 24.7346 160.979 24.0796C161.34 23.3712 161.528 22.5875 161.528 21.7926C161.528 20.9976 161.34 20.214 160.979 19.5056C160.63 18.8481 160.108 18.2979 159.47 17.9137C158.823 17.5357 158.086 17.3365 157.336 17.3365C156.586 17.3365 155.85 17.5357 155.202 17.9137C154.565 18.2979 154.043 18.8481 153.694 19.5056C153.333 20.214 153.145 20.9976 153.145 21.7926C153.145 22.5875 153.333 23.3712 153.694 24.0796C154.041 24.7346 154.56 25.2826 155.195 25.6645C155.849 26.0336 156.586 26.2276 157.336 26.2276C158.087 26.2276 158.824 26.0336 159.477 25.6645V25.6645Z"
                    fill="#084F92"
                />
                <path
                    d="M169.536 28.6603C168.678 28.2642 167.953 27.6265 167.451 26.8252C166.999 26.0913 166.759 25.247 166.756 24.3852H169.974C169.979 24.7025 170.053 25.0149 170.191 25.3006C170.33 25.5864 170.528 25.8386 170.774 26.0396C171.393 26.4975 172.153 26.7216 172.922 26.6722C173.589 26.7195 174.254 26.5514 174.819 26.1926C175.026 26.0419 175.193 25.8426 175.305 25.6123C175.416 25.3819 175.469 25.1276 175.459 24.8718C175.475 24.5273 175.355 24.1903 175.125 23.9334C174.853 23.67 174.518 23.4809 174.152 23.3842C173.593 23.2278 173.024 23.107 172.449 23.0228C171.117 22.9023 169.837 22.4454 168.73 21.6951C168.264 21.3351 167.893 20.8659 167.65 20.3289C167.408 19.7919 167.301 19.2036 167.34 18.6156C167.321 17.7999 167.538 16.9961 167.965 16.3008C168.408 15.6182 169.045 15.0833 169.794 14.7646C170.716 14.3745 171.712 14.1873 172.713 14.2154C173.746 14.1965 174.771 14.393 175.723 14.7924C176.536 15.1285 177.237 15.6875 177.746 16.4051C178.219 17.1112 178.462 17.9466 178.441 18.7963H175.153C175.143 18.5393 175.075 18.2877 174.955 18.0603C174.835 17.8329 174.665 17.6355 174.458 17.4825C173.962 17.1228 173.361 16.9371 172.748 16.9542C172.193 16.9133 171.641 17.071 171.191 17.3991C171.019 17.5343 170.882 17.708 170.791 17.906C170.699 18.1041 170.655 18.321 170.663 18.5391C170.644 18.8446 170.727 19.1476 170.899 19.4011C171.129 19.6575 171.428 19.8411 171.761 19.9294C172.376 20.1179 173.006 20.2551 173.645 20.3395C174.594 20.4677 175.527 20.7009 176.425 21.0347C177.133 21.3011 177.754 21.7582 178.219 22.3554C178.703 23.0276 178.947 23.8426 178.914 24.6702C178.944 25.5888 178.673 26.492 178.142 27.2422C177.613 27.9562 176.889 28.5019 176.057 28.8132C175.062 29.1777 174.009 29.3545 172.95 29.3346C171.775 29.3786 170.606 29.1477 169.536 28.6603Z"
                    fill="#084F92"
                />
                <path
                    d="M184.169 27.7914C183.27 27.0406 182.823 25.6087 182.827 23.4955V17.5451H179.873V14.6603H182.827V10.4895H186.185V14.6951H190.46V17.5799H186.185V23.6831C186.185 25.3376 186.88 26.1717 188.27 26.1717H190.481V28.9523H187.554C186.323 28.9861 185.121 28.5738 184.169 27.7914V27.7914Z"
                    fill="#084F92"
                />
                <path
                    d="M194.922 28.6603C194.063 28.2643 193.339 27.6266 192.837 26.8252C192.385 26.0913 192.145 25.247 192.142 24.3853H195.36C195.364 24.7028 195.437 25.0157 195.575 25.3017C195.713 25.5876 195.913 25.8396 196.159 26.0397C196.775 26.4987 197.534 26.723 198.3 26.6723C198.97 26.7191 199.637 26.551 200.205 26.1926C200.412 26.042 200.579 25.8427 200.69 25.6123C200.802 25.3819 200.855 25.1276 200.845 24.8719C200.861 24.5274 200.741 24.1903 200.511 23.9334C200.239 23.6701 199.904 23.481 199.538 23.3843C198.976 23.2272 198.405 23.1065 197.828 23.0228C196.498 22.9012 195.221 22.4444 194.116 21.6951C193.648 21.3364 193.276 20.8674 193.034 20.3301C192.791 19.7928 192.685 19.2038 192.726 18.6157C192.707 17.8 192.924 16.9962 193.351 16.3009C193.792 15.6168 194.429 15.0815 195.179 14.7646C196.099 14.3743 197.093 14.187 198.092 14.2155C199.125 14.1957 200.15 14.3923 201.102 14.7924C201.918 15.1264 202.622 15.6858 203.132 16.4051C203.605 17.1113 203.848 17.9466 203.827 18.7964H200.539C200.525 18.54 200.456 18.2897 200.336 18.0628C200.216 17.8359 200.048 17.6379 199.844 17.4826C199.324 17.1262 198.7 16.9525 198.071 16.989C197.518 16.948 196.968 17.1057 196.521 17.4339C196.35 17.5691 196.212 17.7428 196.121 17.9409C196.029 18.1389 195.985 18.3558 195.993 18.5739C195.974 18.8795 196.057 19.1825 196.229 19.4359C196.459 19.6923 196.758 19.8759 197.091 19.9642C197.706 20.1527 198.337 20.2899 198.975 20.3744C199.925 20.5011 200.857 20.7343 201.755 21.0695C202.467 21.3338 203.09 21.7912 203.556 22.3902C204.04 23.0625 204.284 23.8774 204.251 24.705C204.281 25.6236 204.01 26.5268 203.479 27.277C202.942 27.997 202.204 28.5433 201.359 28.848C200.364 29.2125 199.311 29.3893 198.252 29.3694C197.102 29.3889 195.964 29.1464 194.922 28.6603V28.6603Z"
                    fill="#084F92"
                />
                <path
                    d="M206.441 28.9733C206.301 28.8938 206.183 28.7787 206.101 28.6396C206.017 28.5038 205.974 28.3472 205.976 28.1878C205.976 28.0296 206.017 27.8741 206.094 27.736C206.176 27.5988 206.294 27.4861 206.434 27.4093C206.59 27.324 206.765 27.2808 206.942 27.2841C207.117 27.281 207.289 27.3242 207.442 27.4093C207.583 27.4861 207.7 27.5988 207.783 27.736C207.885 27.9089 207.926 28.1112 207.9 28.3104C207.873 28.5095 207.781 28.694 207.637 28.8343C207.448 29.0083 207.198 29.1008 206.942 29.0915C206.768 29.0948 206.596 29.0541 206.441 28.9733V28.9733Z"
                    fill="#084F92"
                />
                <path
                    d="M210.98 28.6396C210.436 28.3137 209.99 27.848 209.687 27.291C209.382 26.7201 209.222 26.0825 209.222 25.435C209.222 24.7875 209.382 24.15 209.687 23.579C209.992 23.0234 210.438 22.5581 210.98 22.2304C211.538 21.8961 212.179 21.7251 212.829 21.7369C213.378 21.7287 213.923 21.8451 214.421 22.0775C214.891 22.2961 215.303 22.6224 215.623 23.0298C215.952 23.4381 216.189 23.9122 216.318 24.4201H214.636C214.505 24.0665 214.261 23.766 213.941 23.5651C213.615 23.3667 213.239 23.2654 212.857 23.2731C212.506 23.2744 212.163 23.3699 211.862 23.5497C211.561 23.7295 211.314 23.9869 211.147 24.295C210.964 24.6521 210.868 25.0476 210.868 25.4489C210.868 25.8502 210.964 26.2457 211.147 26.6028C211.319 26.9175 211.574 27.1793 211.884 27.3605C212.177 27.5364 212.514 27.6278 212.857 27.6247C213.245 27.6301 213.626 27.524 213.955 27.3188C214.287 27.0943 214.532 26.7632 214.65 26.3804H216.325C216.201 26.905 215.964 27.3961 215.63 27.8193C215.319 28.2245 214.916 28.5507 214.456 28.7717C213.955 29.0057 213.409 29.1244 212.857 29.1192C212.199 29.1352 211.549 28.9692 210.98 28.6396V28.6396Z"
                    fill="#084F92"
                />
                <path
                    d="M218.947 28.6396C218.399 28.3155 217.946 27.8528 217.633 27.298C217.323 26.7261 217.16 26.0857 217.16 25.4351C217.16 24.7844 217.323 24.144 217.633 23.5721C217.95 23.0054 218.415 22.5354 218.978 22.2123C219.541 21.8891 220.181 21.7248 220.831 21.737C221.496 21.7253 222.153 21.8959 222.728 22.2305C223.278 22.5528 223.731 23.016 224.042 23.5721C224.352 24.144 224.515 24.7844 224.515 25.4351C224.515 26.0857 224.352 26.7261 224.042 27.298C223.734 27.8582 223.277 28.3224 222.721 28.6396C222.144 28.9713 221.489 29.1417 220.824 29.1332C220.165 29.1407 219.517 28.9702 218.947 28.6396V28.6396ZM221.887 27.3258C222.199 27.1431 222.452 26.8754 222.617 26.5542C222.795 26.208 222.888 25.8244 222.888 25.4351C222.888 25.0458 222.795 24.6621 222.617 24.3159C222.449 23.9923 222.194 23.7225 221.88 23.5374C221.564 23.3508 221.204 23.2525 220.838 23.2525C220.471 23.2525 220.111 23.3508 219.795 23.5374C219.481 23.7225 219.226 23.9923 219.058 24.3159C218.88 24.6621 218.787 25.0458 218.787 25.4351C218.787 25.8244 218.88 26.208 219.058 26.5542C219.225 26.8739 219.478 27.141 219.788 27.3258C220.106 27.5055 220.465 27.5999 220.831 27.5999C221.196 27.5999 221.555 27.5055 221.873 27.3258H221.887Z"
                    fill="#084F92"
                />
                <path
                    d="M236.464 22.6126C236.95 23.3709 237.181 24.2647 237.124 25.1637V28.9244H235.497V25.1985C235.54 24.6781 235.406 24.1586 235.115 23.7248C234.985 23.5654 234.818 23.439 234.63 23.3557C234.442 23.2724 234.236 23.2345 234.031 23.2451C233.731 23.2431 233.438 23.3383 233.197 23.5163C232.929 23.7085 232.715 23.9672 232.578 24.267C232.418 24.6083 232.337 24.9814 232.342 25.3584V28.9244H230.722V25.1985C230.765 24.6781 230.63 24.1586 230.34 23.7248C230.209 23.5654 230.043 23.439 229.855 23.3557C229.666 23.2724 229.461 23.2345 229.255 23.2451C228.957 23.2446 228.668 23.3397 228.428 23.5163C228.16 23.7085 227.947 23.9672 227.809 24.267C227.646 24.6071 227.565 24.981 227.573 25.3584V28.9244H225.946V21.9731H227.483V23.0575C227.674 22.6586 227.979 22.325 228.358 22.0982C228.784 21.8594 229.265 21.7369 229.753 21.743C230.241 21.7491 230.719 21.8836 231.139 22.133C231.546 22.4236 231.853 22.8343 232.015 23.3077C232.265 22.8314 232.631 22.4252 233.078 22.126C233.494 21.8636 233.977 21.7283 234.469 21.7367C234.848 21.7077 235.228 21.7725 235.576 21.9252C235.924 22.078 236.229 22.3141 236.464 22.6126V22.6126Z"
                    fill="#084F92"
                />
                <path
                    d="M19.7278 24.8023L1.31371 28.0764C1.15117 28.1042 0.98455 28.0969 0.825068 28.0549C0.665587 28.013 0.516958 27.9373 0.389188 27.833C0.263638 27.7271 0.163802 27.594 0.0972279 27.4438C0.0264143 27.2941 -0.00698271 27.1295 -9.0471e-05 26.9641V4.44179C-0.00317583 4.17906 0.0886314 3.92404 0.258469 3.72355C0.428306 3.52307 0.664772 3.39057 0.924439 3.35042L19.3385 0.0554956C19.5008 0.0173088 19.6698 0.0173088 19.8321 0.0554956C19.9898 0.0984713 20.1367 0.174278 20.2631 0.277944C20.3855 0.386845 20.4848 0.519219 20.555 0.667217C20.624 0.8177 20.6596 0.981329 20.6593 1.14686V23.8013C20.6434 24.0521 20.5424 24.2901 20.3731 24.4758C20.2037 24.6616 19.9761 24.7841 19.7278 24.8231V24.8023Z"
                    fill="#F9CCE0"
                />
                <path
                    d="M23.6414 28.3614L5.2551 31.6494C5.08914 31.6949 4.91467 31.6996 4.74647 31.6633C4.57827 31.627 4.42131 31.5507 4.28887 31.4409C4.1529 31.3355 4.0434 31.1998 3.9691 31.0446C3.89906 30.8874 3.86578 30.7162 3.87178 30.5441V7.98704C3.87716 7.73305 3.96775 7.48824 4.12898 7.29191C4.29467 7.08985 4.52584 6.95214 4.78241 6.90264L23.2452 3.60769C23.4051 3.56957 23.5718 3.56957 23.7318 3.60769C23.8831 3.65496 24.0231 3.73311 24.1427 3.8372C24.2624 3.94129 24.3591 4.06909 24.4269 4.21247C24.4959 4.36296 24.5314 4.52656 24.5312 4.69209V27.2562C24.5319 27.5179 24.4403 27.7716 24.2725 27.9725C24.1047 28.1734 23.8713 28.3087 23.6136 28.3545L23.6414 28.3614Z"
                    fill="#EB4881"
                />
                <path
                    d="M28.2917 31.6772L9.87756 34.9722C9.71018 35.0068 9.53748 35.0068 9.37011 34.9722C9.20439 34.9338 9.04976 34.8576 8.91827 34.7497C8.78491 34.6447 8.67736 34.5105 8.60386 34.3575C8.53036 34.2045 8.49286 34.0366 8.49424 33.8669V11.3237C8.49069 11.0593 8.58201 10.8025 8.75163 10.5997C8.92126 10.3969 9.15794 10.2616 9.41876 10.2184L27.8398 6.93739C28.0045 6.89912 28.1757 6.89912 28.3403 6.93739C28.5046 6.97613 28.6587 7.04961 28.7922 7.15288C28.9281 7.25827 29.0376 7.39395 29.1119 7.54912C29.183 7.70161 29.2209 7.86748 29.2231 8.03572V30.6067C29.212 30.865 29.114 31.112 28.9449 31.3075C28.7758 31.5031 28.5456 31.6358 28.2917 31.6842V31.6772Z"
                    fill="url(#paint0_linear_14_708)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_14_708"
                    x1="26.4565"
                    y1="20.9513"
                    x2="0.270806"
                    y2="20.9513"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#084F92" />
                    <stop offset="0.19" stopColor="#3F4D90" />
                    <stop offset="0.47" stopColor="#894B89" />
                    <stop offset="0.71" stopColor="#BF4A85" />
                    <stop offset="0.88" stopColor="#E04882" />
                    <stop offset="0.98" stopColor="#EB4881" />
                </linearGradient>
                <clipPath id="clip0_14_708_a0">
                    <rect width="237.124" height="35" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
